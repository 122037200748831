/** @jsx jsx */
import { jsx } from "theme-ui"

export default () => {
  return (
      <div
        className="white-background"
        sx={{
          position: "absolute",
          top: 0,
          height: 0,
          width: "100vw",
          borderTop: "100vh solid #fff",
          borderRight: "30vh solid transparent",
          transform: "translateX(-20%)",
          zIndex: 0
        }}
      />
  )
}

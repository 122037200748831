import React from "react"

import BackgroundImage from "../shared/BackgroundImage"
import BlueBackground from "../shared/BlueBackground"

export default ({ imgSrc }) => (
  <div className="fixed-container">
    <BackgroundImage image={imgSrc} />
    <BlueBackground />
  </div>
)

/**
 * Build list of dates as a nested object.
 * Ex: {"2019": {"1": {monthString: "February", days: [2,4,6]}}, "2020": {"4": {monthString: "May", days: [9,21]}}}
 */
export const buildDate = dates => {
  dates = dates.sort()
  let eventDates = {}
  for (const date of dates) {
    let d = new Date(date)
    let year = d.getFullYear()
    let month = d.getMonth()
    let monthString = d.toLocaleString("default", { month: "long" })
    let day = d.getDate()
    let dayString = d.toLocaleString("default", { weekday: "long" })
    let startTime = d
      .toLocaleTimeString(undefined, {
        hour: "2-digit",
      })
      .toLocaleLowerCase()
      .replace(/\s/g, "")
    // remove first character if zero
    if (startTime[0] === "0") {
      startTime = startTime.substring(1)
    }
    if (!eventDates[year]) eventDates[year] = {}
    if (!eventDates[year][month])
      eventDates[year][month] = { monthString, days: [] }
    eventDates[year][month].days.push({ dayNumber: day, dayString, startTime })
  }

  return eventDates
}

/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import LocationDetails from "./LocationDetails"
import EventsView from "./EventsView"
import Admittance from "./Admittance"

export default ({
  location,
  auctionDates,
  viewDates,
  showVerbiage,
  admittance,
  eventStatus,
}) => (
  <div
    sx={{
      width: 330,
      maxWidth: "92vw",
      marginTop: [3, 4],
      marginBottom: 32,
      mx: [3, 5],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100vh",
      position: "relative",
      color: "textWhite",
    }}
  >
    <div className="hidden-text">
      <Styled.h4
        sx={{
          fontSize: [3, 4],
          fontWeight: "heading",
        }}
      >
        Gooding & Company Presents
      </Styled.h4>
    </div>

    <div className="hidden-text" sx={{ marginTop: 1 }}>
      <Styled.h4 sx={{ fontSize: [3, 4], fontWeight: "heading" }}>
        Passion of a Lifetime Auction
      </Styled.h4>
    </div>

    <div className="hidden-text" sx={{ marginTop: [3, "24px"] }}>
      <Styled.h4
        sx={{
          fontSize: [3, 4],
          fontWeight: "heading",
        }}
      >
        Location
      </Styled.h4>
    </div>

    <div className="hidden-text" sx={{ marginTop: 1 }}>
      <Styled.p sx={{ fontSize: [1, 2], fontWeight: "body" }}>
        <LocationDetails
          venueName={location.name}
          location={location.address}
        />
      </Styled.p>
    </div>

    {viewDates.length > 0 && (
      <EventsView
        title="Viewing"
        dates={viewDates}
        city={location.address.addressLocality}
      />
    )}
    {auctionDates.length > 0 && (
      <EventsView
        title="Auction"
        eventStatus={eventStatus}
        dates={auctionDates}
        city={location.address.addressLocality}
      />
    )}

    {admittance ? (
      <Admittance title="Admittance" admittance={admittance} />
    ) : (
      ""
    )}

    {showVerbiage && (
      <div className="hidden-text" sx={{ marginTop: 3 }}>
        <Styled.p
          sx={{ fontSize: [1, 2], fontWeight: "body", fontStyle: "italic" }}
        >
          Collection Offerings to be Announced
        </Styled.p>
      </div>
    )}
  </div>
)

/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import Img from "gatsby-image"
import { getContentfulItemTitle, getPrice } from "../../utils/auctionData"

export default ({ imgSrc, lot, city, year, auctionName, currencies }) => {
  return (
    <div className="grid-item">
      <a
        href={`https://www.goodingco.com/vehicle/${lot.slug}`}
        sx={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="grid-image"
          sx={{ height: "70%", position: "relative" }}
        >
          {imgSrc && (
            <Img
              fluid={imgSrc}
              alt="car"
              sx={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          )}
          <span
            className="lot-number"
            sx={{
              position: "absolute",
              top: "12px",
              left: "8px",
              padding: "2px 8px",
              backgroundColor: "rgba(0,0,0,.4)",
            }}
          >
            <Styled.h3
              sx={{
                fontSize: 2,
                color: "white",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Lot {lot.lotNumber}
            </Styled.h3>
          </span>
        </div>
        <div className="text-content" sx={{ marginTop: 2 }}>
          <Styled.h5
            sx={{
              fontSize: 1,
              textTransform: "uppercase",
              marginTop: [3, 3, 2],
              marginBottom: [3, 3, 2],
              color: "muted",
            }}
          >
            {`${year} | ${auctionName}`}
          </Styled.h5>
          <Styled.h3
            sx={{ fontSize: 2, color: "textDark", fontWeight: "bold" }}
          >
            {getContentfulItemTitle(lot)}
          </Styled.h3>
          <Styled.p sx={{ fontSize: 1, color: "#242424", marginTop: 1 }}>
            {getPrice(lot, currencies[city?.contentful_id])}
          </Styled.p>
        </div>
      </a>
    </div>
  )
}

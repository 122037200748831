/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

export default ({ venueName, location }) => (
  <>
    {venueName} <br />
    {location.__typename === "ContentfulAddress" && (
      <>
        {location.streetAddress}
        <br />
        {location.addressLocality}, {location.addressRegion}
      </>
    )}

    {location.__typename === "ContentfulAddressFreeform" && (
      <>
        {location.text.text.split("\n").map((data, index) => {
          return (
            <span key={index}>
              {data}
              <br />
            </span>
          )
        })}
      </>
    )}
  </>
)

/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Moment from "moment"

const MultiLineDateView = props => {
  const { dates, city } = props
  function getTime(dateTime) {
    if (city === "London") {
      return `${Moment(dateTime).format("HH.mm")}`
    } else {
      return Moment(dateTime).format("h.mma")
    }
  }
  {
    /* Represents A 2 line Datetime, one for date, one for time */
  }
  const multiLineDateView = dates.map((date, index) => (
    <span key={index} className="list-item" sx={{ display: "block" }}>
      {Moment(date.startDate).format("dddd D MMMM")}
      <br />
      {getTime(date.startDate)}
      {date.endDate && ` - ${getTime(date.endDate)}`}
      {city === "London" && " BST"}
    </span>
  ))

  return <>{multiLineDateView}</>
}

export default MultiLineDateView

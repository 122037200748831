import { useStaticQuery, graphql } from "gatsby"

export const useVenues = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulVenue {
          nodes {
            contentful_id
            address {
              ... on ContentfulAddress {
                id
                addressCountry
              }
              ... on ContentfulAddressFreeform {
                id
                addressCountry
              }
            }
          }
        }
      }
    `
  )
  return {
    venues: query.allContentfulVenue.nodes,
  }
}

/** @jsx jsx */
import { jsx } from "theme-ui"

import BackgroundImage from "../shared/BackgroundImage"
import TextContentOverflow from "./TextContentOverflow"
import WhiteBackground from "./WhiteBackground"

export default ({ imgSrc, description }) => (
  <div className="fixed-container">
    <BackgroundImage image={imgSrc} />
    <WhiteBackground />
    <TextContentOverflow description={description[1]}/>
  </div>
)

/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

export default ({ subtitle, description, isSplit }) => (
  <div
    sx={{
      maxWidth: 5,
      px: [3, 5],
      py: ["3vh", 0],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: ["92vh", "100vh"],
    }}
  >
    <div className="hidden-text description-header">
      <Styled.h2
        sx={{
          fontSize: [44, 7],
          fontWeight: "bolder",
          color: "primary",
          textTransform: "uppercase",
          transform: isSplit ? "translateY(100%)" : 0,
        }}
      >
        {subtitle}
      </Styled.h2>
    </div>

    <div
      className="hidden-text"
      sx={{
        marginTop: [2, 3],
        maxHeight: ["69vh", "initial"],
      }}
    >
      <Styled.p
        className="description-text"
        sx={{
          fontSize: 2,
          transform: isSplit ? "translateY(100%)" : 0,
        }}
      >
        {description}
      </Styled.p>
    </div>
  </div>
)

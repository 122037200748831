import React, { useState, useEffect } from "react"
import { ThemeProvider, Styled } from "theme-ui"

import Header from "../../components/header"
import Navigation from "../../components/navigation"
import TransitionContainer from "../../components/shared/TransitionContainer"
import Landing from "../../components/landing"
import GeneralInfo from "../../components/general-info"
import GeneralInfoOverflow from "../../components/general-info-overflow"
import Background from "../../components/background"
import LocationInfo from "../../components/location-info"
import CarGrid from "../../components/grid"
import DescriptionBuilder from "../../utils/DescriptionBuilder"
import "reset-css"
import theme from "../../theme"
import { getAdmittanceData, getAuctionYear } from "../../utils/auctionData"
import { combineCloudinaryWithImages } from "../../utils/images"

export default props => {
  const [activeSlide, setActiveSlide] = useState("landing")
  const [splitGeneralPage, setSplitGeneralPage] = useState(false)

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      (window.innerWidth <= 600 || window.innerHeight <= 500) &&
      auction.description.description.length > 580
    ) {
      setSplitGeneralPage(true)
    }
  }, [])

  const {
    auction,
    auctionDates,
    viewDates,
    auctionDatesMap,
    images,
    subtitle,
    eventDetailsLink,
  } = props

  const auctionYear = getAuctionYear(auction?.subEvents) ?? ""
  const descriptionBuilder = new DescriptionBuilder(auction.description)
  const [auctionLots, setAuctionLots] = useState([])

  useEffect(() => {
    auction.lot?.length > 0 &&
      setAuctionLots(
        auction.lot.map(lot => {
          return {
            ...lot,
            item: {
              ...lot.item,
              featuredImage: combineCloudinaryWithImages(lot.item)[0] ?? {
                fluid: "",
              },
            },
          }
        })
      )
  }, [auction])

  const slides = [
    "landing",
    ...descriptionBuilder.getDescriptionSlides(splitGeneralPage),
    "background",
    "location",
    "preview",
    "grid",
  ]

  const descriptionContent = descriptionBuilder.getDescriptionContent(
    splitGeneralPage
  )

  return (
    <ThemeProvider theme={theme}>
      <Styled.root>
        <div className="auction-microsite">
          <Header
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            eventDetailsLink={eventDetailsLink}
          />

          <TransitionContainer
            type="auction"
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            slides={slides}
          >
            <Landing
              name="landing"
              title={auction.name}
              img={images[0].fluid}
              dates={auctionDatesMap}
              eventStatus={auction.eventStatus}
            />
            <GeneralInfo
              name="general"
              description={descriptionContent}
              subtitle={subtitle}
              imgSrc={images[1].fluid}
              isSplit={splitGeneralPage}
            />
            {splitGeneralPage && (
              <GeneralInfoOverflow
                name="general-overflow"
                description={descriptionContent}
                imgSrc={images[1].fluid}
              />
            )}
            <Background name="background" imgSrc={images[1].fluid} />
            <LocationInfo
              name="location"
              auctionDates={auctionDates}
              viewDates={viewDates}
              location={auction.location}
              imgSrc={images[2].fluid}
              backgroundImgSrc={images[1].fluid}
              showVerbiage={!auction.lot}
              admittance={getAdmittanceData(auction.offers)}
              eventStatus={auction.eventStatus}
            />

            <CarGrid
              name="grid"
              lots={auctionLots}
              year={auctionYear}
              auctionName={auction.name}
              city={auction.location}
            />
          </TransitionContainer>

          <Navigation
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            slides={slides}
          />
        </div>
      </Styled.root>
    </ThemeProvider>
  )
}

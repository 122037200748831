/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

export default ({ description  }) => (
  <div
    sx={{
      maxWidth: 5,
      px: [3, 5],
      py: ["3vh", 0],
      marginTop: 5
    }}
  >
    <div className="hidden-text">
      <Styled.p
        className="description-text"
        sx={{ fontSize: 2 }}
      >
        {description}
      </Styled.p>
    </div>
  </div>
)

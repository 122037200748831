/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

export default ({ image, className = "image-window", alt }) => (
  <div className="image-window-container" sx={{ overflow: "hidden" }}>
    <Img
      className={className}
      fluid={image}
      alt={alt}
      sx={{
        display: ["none", "none", "none", "block"],
        height: "66.66vh",
        width: "50vw",
        objectFit: "cover",
        position: "relative",
      }}
      backgroundColor={"#eeeeee"}
    />
  </div>
)

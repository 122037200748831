/** @jsx jsx */
import { jsx } from "theme-ui"
import get from "lodash/get"
import { graphql } from "gatsby"
import Auction from "./auction"
import { Helmet } from "react-helmet"
import { buildDate } from "src/utils/dateUtils"

export default props => {
  const auction = getAuction()
  function getAuction() {
    const data = get(props, "data")
    return data.auctionsJson || data.contentfulMicrositeAuction
  }

  const auctionDates = auction.auction.subEvents.filter(
    e => e.__typename === "ContentfulSubEventAuction"
  )

  const auctionDatesMap = buildDate(
    auction.auction.subEvents
      .filter(e => e.__typename === "ContentfulSubEventAuction")
      .map(e => e.startDate)
  )

  const viewDates = auction.auction.subEvents
    .filter(e => e.__typename === "ContentfulSubEventViewing")
    .map(e => ({ startDate: e.startDate, endDate: e.endDate }))

  return (
    <div>
      <Helmet title={auction.title} defer={false} />
      <Auction
        auction={auction.auction}
        images={auction.images}
        auctionDates={auctionDates}
        viewDates={viewDates}
        auctionDatesMap={auctionDatesMap}
        subtitle={auction.subtitle}
        eventDetailsLink={auction.eventDetailsLink}
      ></Auction>
    </div>
  )
}

export const pageQuery = graphql`
  query AuctionBySlug($slug: String!) {
    contentfulMicrositeAuction(slug: { eq: $slug }) {
      createdAt(formatString: "MMMM Do, YYYY")
      title
      slug
      subtitle
      eventDetailsLink
      auction {
        name
        eventStatus
        organizer {
          ... on ContentfulOrganization {
            name
          }
        }
        location {
          name
          contentful_id
          address {
            __typename
            ... on ContentfulAddress {
              streetAddress
              addressLocality
              addressRegion
            }
            ... on ContentfulAddressFreeform {
              addressLocality
              text {
                text
              }
            }
          }
        }
        description {
          description
        }
        subEvents {
          __typename
          ... on ContentfulSubEventAuction {
            startDate
          }
          ... on ContentfulSubEventViewing {
            startDate
            endDate
          }
        }
        lot {
          id
          lowEstimate
          highEstimate
          hasReservePrice
          slug
          lotNumber
          item {
            ... on ContentfulAutomobilia {
              id
              title
              __typename
              cloudinaryImagesCombined {
                public_id
                height
                width
              }
            }

            ... on ContentfulVehicle {
              title
              modelYear
              model
              __typename
              make {
                name
              }

              cloudinaryImagesCombined {
                public_id
                height
                width
              }
            }
          }
        }

        offers {
          ... on ContentfulOffer {
            contentful_id
            name
            eligibleQuantity
            eligibleCustomerType {
              title
            }
            price
            acceptedPaymentMethod
            category
            availability
            priceCurrency
            includesObject
          }
          ... on ContentfulBuyersPremium {
            contentful_id
            name
            sys {
              contentType {
                sys {
                  contentful_id
                }
              }
            }
            description {
              description
            }
          }
        }
      }
      images {
        title
        fluid(maxWidth: 1800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
      }
    }
    auctionsJson(slug: { eq: $slug }) {
      name
      slug
      description {
        description
      }
      location {
        name
        address {
          streetAddress
          addressLocality
          addressRegion
        }
      }
      images {
        file {
          url
        }
      }
      subEvents {
        startDate
      }
    }
  }
`

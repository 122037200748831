/** @jsx jsx */
import { jsx } from "theme-ui"

import BackgroundImage from "../shared/BackgroundImage"
import TextContent from "./TextContent"
import WhiteBackground from "./WhiteBackground"

export default ({ subtitle, description, imgSrc, isSplit }) => (
  <div className="fixed-container">
    <BackgroundImage image={imgSrc} />
    <WhiteBackground />
    <TextContent
      subtitle={subtitle}
      description={description[0]}
      isSplit={isSplit}
    />
  </div>
)

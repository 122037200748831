/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import React from "react"
import { AUCTION_EVENT_STATUS } from "src/utils/constants"
import MultiLineDateView from "./MultiLineDateView"

export default ({ title, dates, city, eventStatus }) => {
  const renderDate = () => {
    switch (eventStatus) {
      case AUCTION_EVENT_STATUS.POSTPONED:
        return "Postponed Until Further Notice"
      default:
        return <MultiLineDateView dates={dates} city={city} />
    }
  }

  return (
    <>
      <div className="hidden-text" sx={{ marginTop: [3, "24px"] }}>
        <Styled.h4 sx={{ fontSize: [3, 4], fontWeight: "heading" }}>
          {title}
        </Styled.h4>
      </div>

      <div className="hidden-text" sx={{ marginTop: 1 }}>
        <Styled.p
          sx={{
            fontSize: [1, 2],
            fontWeight: "body",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {renderDate()}
        </Styled.p>
      </div>
    </>
  )
}

/** @jsx jsx */
import { jsx } from "theme-ui"
import { mapVenuesToCurrencies } from "../../utils/auctionData"
import { useVenues } from "../shared/queries/venues"
import GridItem from "./GridItem"

export default ({ data = [], city, year, auctionName }) => {
  data.sort((a, b) => a.lotNumber - b.lotNumber)
  const { venues } = useVenues()
  const currencies = mapVenuesToCurrencies(venues)
  return (
    <div
      className="grid"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
      }}
    >
      {data.map(({ item = {}, ...rest }, index) => (
        <GridItem
          lot={{ ...item, ...rest }}
          key={index}
          city={city}
          imgSrc={item?.featuredImage?.fluid}
          altText="car"
          year={year}
          auctionName={auctionName}
          currencies={currencies}
        />
      ))}
    </div>
  )
}

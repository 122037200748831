/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import React from "react"

export default ({ title, admittance }) => (
  <>
    <div className="hidden-text" sx={{ marginTop: [3, "24px"] }}>
      <Styled.h4 sx={{ fontSize: [3, 4], fontWeight: "heading" }}>
        {title}
      </Styled.h4>
    </div>
    {admittance?.length &&
      admittance.map(({ title }, index) => (
        <div key={index} className="hidden-text" sx={{ marginTop: 1 }}>
          <Styled.p sx={{ fontSize: [1, 2], fontWeight: "body" }}>
            <span className="list-item" sx={{ display: "block" }}>
              {title}
            </span>
          </Styled.p>
        </div>
      ))}
  </>
)

/** @jsx jsx */
import { jsx } from "theme-ui"

import BackgroundImage from "../shared/BackgroundImage"
import ImageWindow from "../shared/ImageWindow"
import LocationText from "./LocationText"
import BlueBackground from "../shared/BlueBackground"

export default ({
  location,
  auctionDates,
  viewDates,
  eventStatus,
  backgroundImgSrc,
  imgSrc,
  showVerbiage,
  admittance,
  eventDetailsLink,
}) => (
  <div className="fixed-container">
    <BackgroundImage image={backgroundImgSrc} />
    <BlueBackground />
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <LocationText
        location={location}
        auctionDates={auctionDates}
        viewDates={viewDates}
        showVerbiage={showVerbiage}
        admittance={admittance}
        eventDetailsLink={eventDetailsLink}
        eventStatus={eventStatus}
      />
      <div sx={{ marginRight: 5, width: [0, "45vw"] }}>
        <ImageWindow
          image={imgSrc}
          alt="Beautiful beach location in Scottsdale, AZ"
        />
      </div>
    </div>
  </div>
)

class DescriptionBuilder {
  constructor(description, subtitle, imgSrc) {
    this.description = description
    this.subtitle = subtitle
    this.imgSrc = imgSrc
    this.data = []
  }


  getDescriptionContent(isSplit) {
    if (isSplit) {
      const words = this.description.description.split(" ")
      const first = words.slice(0, 88).join(" ") + "..."
      const second = "..." + words.slice(88).join(" ")
      return [first, second]
    } else {
      return [this.description.description]
    }
  }

  getDescriptionSlides(isSplit) {
    return isSplit ? ["general-main", "general-overflow"] : ["general"]
  }
}

export default DescriptionBuilder

/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"

import Grid from "./Grid"

export default props => {
  useEffect(() => {
    // initialize Grid Container scroll position on re-render
    const gridContainer = document.querySelector(".grid-container")
    if (gridContainer) gridContainer.scrollTo(0, 1)
  })

  return (
    <div
      id="fixed-grid-container"
      className="fixed-container"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="grid-container">
        <Grid
          data={props.lots}
          city={props.city}
          year={props.year}
          auctionName={props.auctionName}
        />
      </div>
    </div>
  )
}
